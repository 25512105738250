<!--
    status acc :
    1. proses
    2.selesai
    3.ditinjau
    else dibatalkan
 -->
<template>
    <div class="container">

        <Toolbar
            :click-add="showAdd"
            :click-edit="showEdit"
            :click-delete="showDelete"
            :click-refresh="showRefresh"
            :set-filter="loadDataSearch"
            :status-print="false"
            :data   = "recs"
            :fields = "json_fields"
            type    = "xls"
            name    = "Gudang.xls"
        />
        <div class="container-fluid p-0">
            <div id="wrapper">
                <div id="content">
                    <div class="w3-hide-small w3-hide-medium" id="boundary">
                        <table id="header" cellpadding="0" cellspacing="5" border="0" class="table table-hover table-strip">
                            <thead>
                                <tr class="">
                                    <th width="50px">No. </th>
                                    <th v-for="fl in filterByShow" :width="fl.width" :key="fl.name" class="h9 text-center">{{fl.name}}</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="scrlt-y" v-bind:style="'height:'+  (screenHeight-210) +'px;'">
                        <table class="table table-hover table-strip table-bordered col-9 mx-auto">
                            <tbody>
                                <tr v-for="(dt, index) in recs" :key="dt.li_id" class="w3-hover-pale-blue w3-small cursor align-middle text-center" :class="index == selectedRow ?'w3-pale-blue':''">
                                    <td width="50px" class="text-center">{{dt.idx}}
                                        <i
                                        :class="{'fa fa-eye fw-bold': index === selectedRow && dt.po_status != 4}"
                                        @click="openModalDtl(dt.po_id, dt.po_desc, dt.po_status)"
                                        ></i>
                                    </td>
                                    <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','po_id')" class="w3-hide-small w3-hide-medium">
                                        {{dt.po_id}}
                                    </td>
                                    <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','po_dept')" class="w3-hide-small w3-hide-medium">
                                        <span v-html="setDept(dt.po_dept)"></span>
                                    </td>
                                    <!-- <td v-on:click="setKlikRow(index, dt)" :style="'max-width:'+findObjFind('width','po_desc')" class="text-truncate w3-hide-small w3-hide-medium text-start">
                                        {{dt.po_desc}}
                                    </td> -->
                                    <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','po_user')" class="w3-hide-small w3-hide-medium">
                                        {{dt.po_user}}
                                    </td>
                                    <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','po_date')" class="w3-hide-small w3-hide-medium">
                                        {{dt.po_date}}
                                    </td>
                                    <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','po_edit')" class="w3-hide-small w3-hide-medium">
                                        {{dt.po_edit}}
                                    </td>
                                    <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','po_edited')" class="w3-hide-small w3-hide-medium">
                                        {{dt.po_edited}}
                                    </td>
                                    <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','po_status')" class="w3-hide-small w3-hide-medium">
                                        <span v-html="setStatus(dt.po_status)"></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- pagging -->
        <nav class="Page navigation h9 bg-light bg-gradient shadow">
            <div class="d-flex justify-content-center">
                <ul class="pagination text-center">
                    <li class="page-item page-item active"><a :class="pages.halaman == 1 ?'dsb':''" class="page-link" @click="first()" href="#">First</a></li>
                    <li class="page-item page-item active"><a :class="pages.halaman == 1 ?'dsb':''" class="page-link" @click="prev()" href="#">«</a></li>
                    <li class="page-item page-item active"><a class="page-link px-0 px-md-2" href="#">Page {{pages.halaman}} sd {{pages.ttlhalaman}}</a></li>
                    <li class="page-item page-item active"><a :class="pages.halaman == pages.ttlhalaman ?'dsb':''" class="page-link" @click="next()" href="#">»</a></li>
                    <li class="page-item page-item active"><a :class="pages.halaman == pages.ttlhalaman ?'dsb':''" class="page-link" @click="last()" href="#">Last</a></li>
                    <li class="page-item page-item active"><a class="page-link  page-item active" href="#">Rec {{pages.ttlrec}}</a></li>
                    </ul>
            </div>
        </nav>
        <div id="modal-form" class="modal row top-50 start-50 translate-middle ms-md-0 ms-1 modal-lg">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="w3-animate-zoom modal-content">
            <div class="modal-header pb-0">
                <button onclick="document.getElementById('modal-form').style.display='none'"
                class="btn w3-display-topright btn-lg">&times;</button>
                <p class="h9 fw-bold"><i class="mdi mdi-file-document-multiple-outline"></i>&nbsp; Add / Edit data</p>
            </div>
            <div class="modal-body">
                <form class="px-3" id="frmGdg" v-on:submit.prevent="saveData" autocomplete="off">
                    <div class="mb-3 row">
                            <!-- <div class="col-12">
                                <label for="po_desc" class="form-label h9 fw-bold">{{findObjFind('name','po_desc')}} :</label>
                                <textarea ref="po_desc" type="text" class="form-control mb-3 " id="po_desc" :placeholder="findObjFind('name','po_desc')" v-model="rec.po_desc"></textarea>

                            </div> -->
                            <!-- <div class="modal-header pb-0 mb-3">
                                <p class="h9 fw-bold"><i class="mdi mdi-file-document-multiple-outline"></i>&nbsp; Tambah Item</p>
                            </div> -->

                            <input type="text" v-model="rec.po_id" hidden>
                            <div class="col-12">
                                <label for="poi_name" class="form-label h9 fw-bold">{{findObjFind('name','poi_name')}} :</label>
                                <input ref="poi_name" type="text" class="form-control mb-3" id="poi_name" :placeholder="findObjFind('name','poi_name')" v-model="rec.poi_name">
                            </div>
                            <div class="col-md-9">
                                <label class="form-label h9 fw-bold">{{findObjFind('name','inv_name')}} :</label>
                                <model-list-select
                                    :list="invs"
                                    class="mb-3 form-control"
                                    option-value="inv_id"
                                    option-text="inv_name"
                                    v-model="rec.inv_id"
                                    placeholder="Pilih Kategori"
                                ></model-list-select>
                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="poi_qty" class="form-label h9 fw-bold">{{findObjFind('name','poi_qty')}} :</label>
                                <input ref="poi_qty" type="number" class="form-control mb-3" id="poi_qty" :placeholder="findObjFind('name','poi_qty')" v-model="rec.poi_qty">
                            </div>
                            <div class="col-12">
                                <label for="poi_desk" class="form-label h9 fw-bold">{{findObjFind('name','poi_desk')}} :</label>
                                <textarea  ref="poi_desk" type="text" class="form-control mb-3" id="poi_desk" :placeholder="findObjFind('name','poi_desk')" v-model="rec.poi_desk"></textarea>
                            </div>
                            <div class="col-12">
                                <a @click="tambahItem" class="btn btn-primary col-12 fw-bold mb-3" type="submit">Tambah Item</a>
                            </div>
                            <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">No</th>
                                    <th scope="col">Nama Item</th>
                                    <th scope="col">Deskripsi</th>
                                    <th scope="col">Kategori</th>
                                    <th scope="col">Qty</th>
                                    <th width="30px">
                                        aksi
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(dt, index) in rex" :key="index" class="w3-hover-pale-blue w3-small cursor">
                                        <td>{{ index + 1 }}</td>
                                        <td><input v-model="dt.poi_name" class="form-control" :readonly="stsAdd"></td>
                                        <td><input v-model="dt.poi_desk" class="form-control" :readonly="stsAdd"></td>
                                        <td>{{dt.deskripsi}}</td>
                                        <td style="width: 100px;"><input v-model="dt.poi_qty" class="form-control" :readonly="stsAdd"></td>
                                        <td>
                                            <a type="button" class="w3-btn w3-tiny w3-red w3-round-large" @click="removeItem(index)">
                                                <i class="fa fa-remove"></i>
                                            </a>
                                        </td>
                                      </tr>
                                </tbody>
                              </table>
                              <div v-if="!rex.length">Tidak ada item yang di pilih</div>

                    </div>
                </form>
            </div>
            <div class="modal-footer"  style="margin-top: 15px;">
                <h6 class="w3-right">
                <button form="frmGdg" class="btn btn-success rounded" type="submit"><i class="fa fa-save"></i> Save</button>
                <button type="button" class="btn btn-danger ms-2 rounded" onclick="document.getElementById('modal-form').style.display='none'"><i class="fa fa-close"></i> Close</button>
                </h6>
            </div>
            </div>
        </div>
        </div>

        <div id="modal-dtl" class="modal row mt-4 top-50 start-50 translate-middle ms-md-0 ms-1 modal-lg">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="w3-animate-zoom modal-content">
            <div class="modal-header pb-0">
                <button onclick="document.getElementById('modal-dtl').style.display='none'"
                class="btn w3-display-topright btn-lg">&times;</button>
                <p class="h9 fw-bold"><i class="mdi mdi-file-document-multiple-outline"></i>&nbsp; Detail Sparepart {{ Poid }}</p>
            </div>
            <div class="modal-body">
                <b>Deskripsi :</b><br>
                <p class="mb-3">
                    {{ Podesc }}
                </p>
                  <form id="frmDetail" v-on:submit.prevent="updateDtl" autocomplete="off">
                    <div id="accordion" class="accordion">
                        <div v-for="(item,index) in itemdtl" :key="item.poi_id" class="p-1">
                          <div class="accordion-item">
                            <h2 class="accordion-header" :id="'heading-' + item.poi_id">
                              <button
                                class="accordion-button p-2 px-3"
                                type="button"
                                data-bs-toggle="collapse"
                                :data-bs-target="'#collapse-' + item.poi_id"
                                aria-expanded="false"
                                :aria-controls="'collapse-' + item.poi_id"
                              >
                              <div class="row col-12 align-items-center">
                                <div class="col-1">
                                    {{ index + 1 }}.
                                </div>
                                <div class="col-4">
                                    {{ item.deskripsi }}
                                </div>
                                <div class="col-2 fw-bold">
                                    Qty : {{ item.poi_qty }}
                                </div>
                                <div class="col-3 fw-bold">
                                    Status kedatangan barang : <span v-html="setStatuss(item.poi_status)"></span>
                                </div>
                                <div class="form-check form-switch col-2" v-if="item.poi_status != 1 && poSt == 1">
                                    <a class="btn btn-success btn-sm" @click="updateStat(item.poi_id)">Selesai</a>
                                </div>
                            </div>

                            </button>
                            </h2>
                            <div
                              :id="'collapse-' + item.poi_id"
                              class="accordion-collapse collapse"
                              :class="{ show: !item.collapsed }"
                              :aria-labelledby="'heading-' + item.poi_id"
                              data-bs-parent="#accordion"
                            >
                              <div class="accordion-body" v-if="departemen == 1 || departemen == 2">
                                <p class=""><b>Keterangan : </b>{{item.poi_desk}}</p>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>no</th>
                                            <th>rate</th>
                                            <th width="70px">qty</th>
                                            <th width="70px">top</th>
                                            <th width="180px">supplier</th>
                                            <th>total</th>
                                            <th v-if="departemen == 1">Approved</th>
                                        </tr>
                                    </thead>

                                        <tbody v-if="item.poi_status !== 1">
                                            <tr v-for="(dtl,index) in item.details" :key="dtl.poid_id">
                                                <td>{{index + 1}}</td>
                                                <td><input type="number" class="form-control" :readonly="item.poi_status == 2" v-model="dtl.poid_rate"/></td>
                                                <td width="70px"><input type="text" readonly class="form-control" v-model="item.poi_qty"/></td>
                                                <td width="70px"><input type="text" :readonly="item.poi_status == 2" class="form-control" v-model="dtl.poid_top"/></td>
                                                <td width="180px">
                                                    <model-list-select
                                                    :isDisabled="item.poi_status == 2"
                                                        :list="supp"
                                                        class="w3-small"
                                                        option-value="si_id"
                                                        option-text="si_name"
                                                        v-model="dtl.si_id"
                                                        :readonly="item.poi_status == 2"
                                                        placeholder="Pilih Sparepart"
                                                    ></model-list-select>
                                                </td>
                                                <td><input type="text" readonly class="form-control" :value="kali(item.poi_qty,dtl.poid_rate)"/></td>
                                                <td v-if="departemen == 1">
                                                    <div class="form-check form-switch">
                                                        <input
                                                            class="form-check-input"
                                                            type="checkbox"
                                                            role="switch"
                                                            v-model="dtl.poid_status"
                                                            @change="handleCheckboxChange(item, dtl, index)"
                                                            :disabled="index !== selectedRowIndex && selectedRowIndex !== null"
                                                            >
                                                    </div>
                                                </td>
                                            </tr>
                                      </tbody>

                                      <tbody v-if="item.poi_status == 1">
                                        <tr v-for="(dtl, index) in filteredDetails(item)" :key="dtl.poid_id">
                                            <td>{{ index + 1 }}</td>
                                            <td><input type="text" readonly  class="form-control" v-model="dtl.poid_rate" /></td>
                                            <td width="70px"><input type="text" readonly class="form-control" v-model="item.poi_qty" /></td>
                                            <td width="70px"><input type="text" readonly  class="form-control" v-model="dtl.poid_top" /></td>
                                            <td width="180px">
                                                <model-list-select
                                                isDisabled
                                                    :list="supp"
                                                    class="w3-small"
                                                    option-value="si_id"
                                                    option-text="si_name"
                                                    v-model="dtl.si_id"
                                                    placeholder="Pilih Sparepart"
                                                ></model-list-select>
                                            </td>
                                            <td><input type="text" readonly class="form-control" :value="kali(item.poi_qty, dtl.poid_rate)" /></td>

                                        </tr>
                                      </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                        <div v-if="departemen == 1 || departemen == 2">
                            <button type="submit" v-if="poSt != 2" id="frmDetail" class="btn btn-primary  ms-2 rounded btn-sm"><i class="fa fa-wrench"></i> Update</button>
                            <button type="button" v-if="poSt != 2" @click="showAct(Poid,3)" class="btn btn-success  ms-2 rounded btn-sm"><i class="fa fa-send"></i> Ajukan</button>
                        </div>
                  </form>
                  <div v-if="!itemdtl.length">Tidak ada sparepart yang di pakai</div>
            </div>
            <div class="modal-footer d-flex justify-content-between"  style="margin-top: 15px;">
                <div class="" v-if="departemen == 1 || departemen == 2">
                    <button type="button" v-if="poSt == 1" class="btn btn-success ms-2 rounded btn-sm" @click="showAct(Poid,2)"><i class="fa fa-check"></i> Selesai</button>
                    <button type="button" v-if="poSt != 2" class="btn btn-danger ms-2 rounded btn-sm" @click="showAct(Poid,4)"><i class="fa fa-close"></i> Batal</button>

                </div>
                <button type="button" class="btn btn-danger ms-2 rounded" onclick="document.getElementById('modal-dtl').style.display='none'"><i class="fa fa-close"></i> Close</button>
            </div>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import '@popperjs/core';
import Config from '@/conf/Config.js';
import axios from "axios";
import "vue-search-select/dist/VueSearchSelect.css";
import Toolbar from "@/components/Toolbar.vue";
import { ModelListSelect } from "vue-search-select";

export default {
    // head: {
    //     title: {
    //       inner: 'Main'
    //     }
    // },
    name : 'Perbaikan',
    components: {
        Toolbar,
        ModelListSelect
    },
    data() {
        return {
            username : localStorage.username,
            departemen : localStorage.dept,
            screenHeight : 0,
            selectedRow : -1,
            DatePickerFormat: 'YYYY-MM-DD',
            selectedRowIndex: null,
            rec: {},
            qty: '', // Variabel untuk menyimpan nilai qty
            qty2: '',
            Poid:'',
            dtl: { poid_status: false },
            Podesc:'',
            poSt:'',
            recs: [],
            supp: [],
            rei:{},
            rex:[],
            checkedItems: [],
            showModalDtl: true,
            itemdtl: [],
            kendaraans: [],
            invs: [],
            itemt: [],
            recItems : [],
            pages : {halaman : 1, batas : 0, ttlhalaman : 0, ttlrec:0, limit:Config.perpage, search:''},
            filters : {
            	operator : 'AND',
                fields :[
                        {fieldFind : '', key : 'po_id', type: 'text', name : 'ID Order', filter : true, width : '120px', grid : true},
                        {fieldFind : '', key : 'po_dept', type: 'text', name : 'Departemen', filter : true, width : '70px', grid : true},
                        // {fieldFind : '', key : 'po_desc', type: 'text', name : 'Reason', filter : true, width : '200px', grid : true},
                        {fieldFind : '', key : 'po_user', type: 'text', name : 'User Add', filter : true, width : '100px', grid : true},
                        {fieldFind : '', key : 'po_date', type: 'text', name : 'Tanggal Pengajuan', filter : true, width : '100px', grid : true},
                        {fieldFind : '', key : 'po_edit', type: 'text', name : 'Tanggal Edit', filter : true, width : '100px', grid : true},
                        {fieldFind : '', key : 'po_edited', type: 'text', name : 'User Edit', filter : true, width : '80px', grid : true},
                        {fieldFind : '', key : 'po_status', type: 'text', name : 'Status Acc', filter : true, width : '80px', grid : true},
                        {fieldFind : '', key : 'poi_name', type: 'text', name : 'Nama Item', filter : true, width : '80px', grid : false},
                        {fieldFind : '', key : 'poi_desk', type: 'text', name : 'Deskripsi', filter : true, width : '80px', grid : false},
                        {fieldFind : '', key : 'poi_qty', type: 'text', name : 'Qty', filter : true, width : '80px', grid : false},
                        {fieldFind : '', key : 'inv_name', type: 'text', name : 'Kategori', filter : true, width : '80px', grid : false},
            ],
                find : []
            },
            /**Untuk transfer excel*/
            json_fields : {
                'ID' : 'g_id',
                'NAME' : 'g_name',
                'KETERANGAN' : 'g_keterangan'
            },
        }
    },
    methods : {
        handleCheckboxChange(item, dtl, index) {
        // Uncheck previously selected row
        if (this.selectedRowIndex !== null && this.selectedRowIndex !== index) {
            item.details[this.selectedRowIndex].poid_status = false;
        }
        // Update selectedRowIndex
        this.selectedRowIndex = dtl.poid_status ? index : null;
        },
        showAlert() {
            alert('Button clicked!');
        },
        findObjFind(key, val){
            return this.findObj(this.filters.fields,'key',val)[key];
        },
        updatePoidStatus(dtl) {
        // Update poid_status to 1 if true, 2 if false
        dtl.poid_status = dtl.poid_status ? "1" : "0";
        },
        findField(val){
            return this.findObj(this.fieldRules, 'field',val);
        },
        findObj(obj, key, value) {
            let ketemu = 0;
            for (var pkey in obj) {
                if (obj[pkey][key] == value) {
                    break;
                } else {
                    ketemu++;
                }
            }
            if(obj[ketemu]!=undefined){
                return obj[ketemu];
            }else
                return null;
        },
        first(){
            let self = this;
            self.pages.halaman = 1;
            self.loadData();
        },
        prev(){
            let self = this;
            self.pages.halaman = self.pages.halaman - 1;
            self.loadData();
        },
        next(){
            let self = this;
            self.pages.halaman = self.pages.halaman + 1;
            self.loadData();
        },
        last(){
            let self = this;
            self.pages.halaman = self.pages.ttlhalaman;
            self.loadData();
        },
        setKlikRow(index, dt){
            let self = this;
            self.selectedRow = index;
            self.rec = Object.assign({}, dt);
        },
        kali(qty, rate) {
            // Ensure qty and rate are valid numbers before performing the calculation
            qty = parseFloat(qty) || 0;
            rate = parseFloat(rate) || 0;

            return (qty * rate).toFixed(2); // You can adjust the number of decimal places as needed
        },
        setDept(dt) {
            if (dt === "1") {
                return "<div class='btn btn-light btn-sm' >IT</span></div>";
            } else if ( dt === "2") {
                return "<div class='btn btn-light btn-sm'>Purchasing</span><div>";
            } else if ( dt === "3") {
                return "<div class='btn btn-light btn-sm'>Kendaraan</span><div>";
            } else if ( dt === "4") {
                return "<div class='btn btn-light btn-sm'>Gudang</span><div>";
            } else {
                return "<div class='btn btn-light btn-sm'>Kantor</span><div>";
            }
        },
        setStatus(dt) {
            if (dt === "1") {
                return "<div class='btn btn-primary btn-sm ' >Proses</span></div>";
            } else if (dt === "2") {
                return "<div class='btn btn-success btn-sm '>Selesai</span><div>";
            } else if (dt === "3") {
                return "<div class='btn btn-warning btn-sm p-1 '>Di Tinjau</span><div>";
            } else {
                return "<div class='btn btn-danger btn-sm '>Di Batalkan</span><div>";
            }
        },
        setStatuss(dt) {
            if (dt === "1") {
                return "<div class='btn btn-success btn-sm ' >Selesai</span></div>";
            } else if (dt === "2") {
                return "<div class='btn btn-primary btn-sm '>Proses</span><div>";
            } else {
                return "<div class='btn btn-warning btn-sm '>Pengajuan</span><div>";
            }
        },
        async tambahItem(){
            const selectedInventory = this.invs.find(inv => inv.inv_id === this.rec.inv_id);
            const pemDeskripsi = selectedInventory.inv_name;

            let self = this;
            // self.stsAdd = true;

            let key = await axios.post("admin/ApiGetId.php",{
                pfunction : 'getId',
                vc_prefix : 'POI',
                vc_table : 'tb_pembelian_order_item',

            });

            this.rex.push({
                deskripsi : pemDeskripsi,
                poi_id: key.data,
                po_id: this.rec.po_id,
                poi_name: this.rec.poi_name,
                poi_desk: this.rec.poi_desk,
                poi_qty: this.rec.poi_qty,
                inv_id: this.rec.inv_id,
                pfunction : 'add',
            });

            this.rec.poi_name = '';
            this.rec.inv_id = '';
            this.rec.poi_qty = '';
            this.rec.poi_desk = '';

        },
        async updateDtl(event) {
            try {
                const poids = this.Poid;

                const details = this.itemdtl.flatMap(item => item.details.map(dtl => ({
                    poid_id: dtl.poid_id,
                    poid_rate: dtl.poid_rate,
                    poid_top: dtl.poid_top,
                    si_id: dtl.si_id,
                    poid_status: dtl.poid_status,
                    pfunction: 'updateI',
                    // Add other details properties as needed
                })));

                await Promise.all(details.map(async key => {
                    const response = await axios.post("invent/ApiPoI.php", key);

                    if (!response.data.success) {
                        console.log("Update failed:", response.data);
                        throw new Error("Update failed");
                    }
                }));

                this.$toast.success("Update successful");
                document.getElementById('modal-dtl').style.display = 'none';

                const approvalResponse = await axios.post("invent/ApiPo.php", {
                    po_id: poids,
                    status: 1,
                    pfunction: "approved"
                });

                if (approvalResponse.data.success) {
                    swal({ title: "Sukses", text: msg, timer: 1000, type: "success" });
                    document.getElementById("modal-dtl").style.display = "none";
                    this.loadData();
                } else {
                    swal({ title: "Delete", text: "Gagal Ubah Status", type: "error", html: true });
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },

        loadData(){
            this.screenHeight = window.innerHeight;
            let self = this;
            self.selectedRow = -1;
            self.rec = {};
            let params = {
                pfunction:'show',
                limit: self.pages.limit,
                page: self.pages.halaman,
                search: self.pages.search,
                dept: localStorage.dept,
            }
            axios.post("invent/ApiPo.php",params).then(function (response) {
                let idx = (self.pages.limit*self.pages.halaman)-self.pages.limit+1;
                self.pages.ttlrec = parseFloat(response.data.ttlrec);
                self.pages.ttlhalaman = Math.ceil(parseFloat(response.data.ttlrec)/self.pages.limit);
                response.data.rec.map(function(val){
                    val['idx'] = idx;
                    idx++;
                });
                self.recs = response.data.rec;
            });
        },
        showRefresh(){
            this.pages.search = "";
           this.loadData();
        },
        loadDataSearch(filter){
            this.pages.search = filter;
            this.loadData();
        },
        openModalDtl(poId,poDesc,poStat) {
            var self = this;
            axios.post("invent/ApiPoI.php", {
                pfunction: 'showall',
                po_id: poId
            }).then(function (response) {
                self.itemdtl = response.data.rec;
                document.getElementById("modal-dtl").style.display = "block";
            }).catch(function (error) {
                console.error('Error fetching data:', error);
            });
            self.Poid = poId;
            self.Podesc = poDesc;
            self.poSt = poStat;
        },
        async showAdd() {
            let self = this;
            self.stsAdd = true;

            let key = await axios.post("admin/ApiGetId.php",{
                pfunction : 'getId',
                vc_prefix : 'PO',
                vc_table : 'tb_pembelian_order',

            });

            self.rec = {
                po_id : key.data,
                po_desc : '',
                po_user: self.username,
                po_dept : localStorage.dept,
                po_status : '3',
            };
            self.rex = [],
            document.getElementById("modal-form").style.display = "block";

        },
        showEdit(){
            let self = this;
            if (self.selectedRow < 0) {
                this.$toast.error('pilih Item.!');
                return false;
            }else{
                self.stsAdd = false;
                if (self.rec.po_status == 3){
                    axios.post("invent/ApiPoI.php", {
                    pfunction: 'showall',
                    po_id: self.rec.po_id
                }).then((response) => {
                    self.rex = response.data.rec.map((item) => {

                    return { ...item, pfunction: 'edit' };
                });
                }).catch((error) => {
                    // Handle errors here
                    console.error('Error fetching data:', error);
                });
                    document.getElementById('modal-form').style.display='block';
                } else {
                    this.$toast.error('item tidak bisa di edit');
                    return false;
                }

            }
        },
        async saveData(event) {
            try {
                if (this.rex.length === 0) {
                    this.showAlert('Tambahkan item terlebih dahulu', 'warning');
                    return; // Stop the form submission
                }

                const isNewRecord = this.stsAdd;
                this.rec.pfunction = isNewRecord ? "add" : "edit";
                this.rec.li_user = isNewRecord ? null : this.username;

                const response = await axios.post("invent/ApiPo.php", this.rec);

                if (response.data.success) {
                    this.$toast.success(response.data.msg);
                    document.getElementById('modal-form').style.display = 'none';
                    await this.saveItems();

                    if (isNewRecord) {
                        this.recs.unshift(this.rec); // Insert at the beginning
                    } else {
                        this.$set(this.rec, this.selectedRow, this.recs, this.dt); // Update at the specified index
                    }

                    this.selectedRow = -1;
                } else {
                    this.$toast.error(response.data.msg);
                }
            } catch (error) {
                console.error("An error occurred while saving data:", error);
            }

            event.preventDefault();
            return false;
        },

        async saveItems() {
            try {
                const { rex } = this;

                await Promise.all(rex.map(async key => {
                    const response = await axios.post("invent/ApiPoI.php", key);

                    if (response.data.success) {
                        console.log("Sukses", response.data.messages);
                        this.$set(this.rec);
                    } else {
                        console.log("Gagal", response.data);
                    }
                }));
            } catch (error) {
                console.error("An error occurred while saving items:", error);
            }
        },
        updateStat(poid) {
                let self = this;

                swal({
                    title: "Yakin untuk menyelesaikan order barang?",
                    text: "Data yang sudah diselesaikan tidak dapat dibatalkan",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Ya",
                    cancelButtonText: "Tidak",
                    closeOnConfirm: false,
                    closeOnCancel: true,
                }, function(isConfirm) {
                    if (isConfirm) {
                        var vparams = {
                            poi_id: poid,
                            pfunction: "selesai"
                        };

                        axios.post("invent/ApiPoI.php", vparams)
                            .then(function(response) {
                                if (response.data.success) {
                                    // Use response.data.msg to get the message from the server
                                    swal({
                                        title: "Sukses",
                                        text: response.data.msg,
                                        timer: 1000,
                                        type: "success"
                                    });
                                    self.loadData();
                                    document.getElementById("modal-dtl").style.display = "none";
                                } else {
                                    swal({
                                        title: "Gagal",
                                        text: "Gagal menyelesaikan order",
                                        type: "error",
                                        html: true
                                    });
                                }
                            })
                            .catch(function(error) {
                                // Handle axios request error
                                console.error("Axios error:", error);
                                swal({
                                    title: "Error",
                                    text: "Terjadi kesalahan pada server",
                                    type: "error",
                                    html: true
                                });
                            });
                    }
                });
            },
        showAct(poId,Act) {
            var self = this;

                var title = "Yakin Ingin Menghapus Data ..!";
                var msg = "order telah selesai";

                if (Act == 1) {
                    title = "Yakin Untuk Memproses Order ?";
                    msg = "Order berhasil di proses";
                } else if (Act == 2) {
                    title = "Yakin Ingin Menyelesaikan Order ?";
                    msg = "Order berhasil di selesaikan";
                } else if (Act == 3) {
                    title = "Yakin Ingin Melakukan Pengajuan Order ?";
                    msg = "Pengajuan Order berhasil";
                } else {
                    title = "Yakin Ingin Membatalkan Order ?";
                    msg = "Order di batalkan";
                }

                swal(
                    {
                        title: title,
                        text: "Pastikan untuk mengoreksi data terlebih dahulu , sebelum melakukan aksi !",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Ya",
                        cancelButtonText: "Tidak",
                        closeOnConfirm: false,
                        closeOnCancel: true,
                    },
                    function(isConfirm) {
                        if (isConfirm) {
                            var vparams = {
                                po_id : poId,
                                status : Act,
                                pfunction : "approved"
                            };
                            axios.post("invent/ApiPo.php",vparams).then(function (response) {
                                if (response.data.success == true) {
                                    swal({title: "Sukses",text: msg,timer: 1000,type: "success"});
                                    self.loadData();
                                    document.getElementById("modal-dtl").style.display = "none";
                                }else{
                                    swal({title: "Delete",text: "Gagal delete data",type: "error",html: true});
                                }
                            });
                        }
                    }
                );

        },
        showDelete() {
            var self = this;
            if (self.selectedRow < 0) {
                this.$toast.error('pilih Laporan.!');
                return false;
            }else{
                if (self.rec.po_status == 3){
                swal(
                    {
                        title: "Yakin Ingin Menghapus Data ..!",
                        text: "Data Yang sudah di hapus tidak akan bisa dikembalikan",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Ya, Hapus Data",
                        cancelButtonText: "Tidak, Batal Hapus",
                        closeOnConfirm: false,
                        closeOnCancel: true,
                    },
                    function(isConfirm) {
                        if (isConfirm) {
                            var vparams = {
                                po_id : self.rec.po_id,
                                pfunction : "delete"
                            };
                            axios.post("invent/ApiPo.php",vparams).then(function (response) {
                                if (response.data.success == true) {
                                    swal({title: "Hapus..!",text: "Data Sudah terhapus",timer: 1000,type: "success"});
                                    self.$delete(self.recs, self.selectedRow);
                                }else{
                                    swal({title: "Delete",text: "Gagal delete data",type: "error",html: true});
                                }
                            });
                        }
                    }
                );
            } else {
                    this.$toast.error('item tidak bisa di edit');
                    return false;
                }
            }
        },
        removeItem(index) {
            const confirmed = window.confirm("Apakah Anda yakin ingin menghapus item ini?");
            if (confirmed) {
            const removedItem = this.rex[index];
            axios.post("invent/ApiPoI.php", {
            pfunction: 'delete',
            poi_id: removedItem.poi_id  // Corrected pei_id value
            });
            // Kembalikan nilai qty ke inventaris
            const selectedInventory = this.invs.find(inv => inv.pem_id === removedItem.pem_id);
            if (selectedInventory) {
                selectedInventory.pem_qty += parseFloat(removedItem.qty);
            }

            // Hapus item dari rex
            this.rex.splice(index, 1);
            }
        },
        showAlert(message, type) {
            // Create a floating Bootstrap alert
            const alertContainer = document.getElementById('alert-container');
            const alertElement = document.createElement('div');
            alertElement.className = `alert alert-${type} alert-dismissible fade show fixed-top`;
            alertElement.role = 'alert';
            alertElement.innerHTML = `
            ${message}
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            `;

            // Append the alert to the container
            alertContainer.appendChild(alertElement);

            // Automatically remove the alert after a certain time (e.g., 5 seconds)
            setTimeout(() => {
            alertElement.remove();
            }, 5000);
        },
        setInit() {
            let self = this;
            axios.post("invent/ApiInv.php",{
                pfunction : 'showis',
                dept : localStorage.dept
            }).then(function (response) {
                self.invs = response.data.rec;
            });
            axios.post("invent/ApiSupp.php",{
                pfunction : 'showall'
            }).then(function (response) {
                self.supp = response.data.rec;
            });
        },
    },
    filters: {
        toRp(value) {
            return `${Intl.NumberFormat("de-DE").format(value)}`;
        },
        pNumber(num) {
            var x = num.replace(/\D/g, '').match(/(\d{0,4})(\d{0,4})(\d{0,4})/);
            return !x[2] ? x[1] : '(' + x[1] + ')-' + x[2] + (x[3] ? '' + x[3] : '');
        }
    },
    watch: {
        // 'moveCenter': function(){
        //     console.log("OK");
        // }
    },
    computed :{
        filteredDetails() {
            return item => item.details.filter(dtl => dtl.poid_status == 1);
        },
        filterByShow(){
            return  this.filters.fields.filter(event => {
                return event.grid == true;
            });
        },
        getCheckboxValue() {
            return this.itemdtl.flatMap(item => item.details.map(dtl => ({
  tess: dtl.poid_status ? 1 : 0,
  // Add other properties or modify as needed
})));
        },
    },
    mounted(){
        this.screenHeight = window.innerHeight;
        this.setInit();
        this.loadData();
    }
};
</script>

<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

</style>